function LoadInservioWebScript() {
  var nav = function () {
    $('.gw-nav > li > a').click(function () {
      var gw_nav = $('.gw-nav');
      gw_nav.find('li').removeClass('active');
      $('.gw-nav > li > ul > li').removeClass('active');

      var checkElement = $(this).parent();
      var ulDom = checkElement.find('.gw-submenu')[0];

      if (ulDom == undefined) {
        checkElement.addClass('active');
        $('.gw-nav').find('li').find('ul:visible').slideUp();
        return;
      }
      if (ulDom.style.display != 'block') {
        gw_nav.find('li').find('ul:visible').slideUp();
        gw_nav.find('li.init-arrow-up').removeClass('init-arrow-up').addClass('arrow-down');
        gw_nav.find('li.arrow-up').removeClass('arrow-up').addClass('arrow-down');
        checkElement.removeClass('init-arrow-down');
        checkElement.removeClass('arrow-down');
        checkElement.addClass('arrow-up');
        checkElement.addClass('active');
        checkElement.find('ul').first().slideDown(300);
      } else {
        checkElement.removeClass('init-arrow-up');
        checkElement.removeClass('arrow-up');
        checkElement.removeClass('active');
        checkElement.addClass('arrow-down');
        checkElement.find('ul').slideUp(300);

      }
    });
    $('.gw-nav > li > ul > li').hover(function () {
      $(this).find('ul').addClass('active');
      $(this).addClass('active');
    },
      function () {

        $(this).find('ul').removeClass('active');
        $(this).removeClass('active');
      });
    $('.outer-sub > li > a').click(function () {
      var checkElement = $(this).parent();
      var ulDom = checkElement.find('.gw-submenu')[0];

      if (ulDom == undefined) {
        checkElement.addClass('active');
        $('.gw-nav').find('li').find('ul:visible').slideUp();
        return;
      }
      if (ulDom.style.display != 'block') {
        var gw_nav = $('.outer-sub');
        gw_nav.find('li').find('ul:visible').slideUp();
        gw_nav.find('li.init-arrow-up').removeClass('init-arrow-up').addClass('arrow-down');
        gw_nav.find('li.arrow-up').removeClass('arrow-up').addClass('arrow-down');
        checkElement.removeClass('init-arrow-down');
        checkElement.removeClass('arrow-down');
        checkElement.addClass('arrow-up');
        checkElement.addClass('active');
        checkElement.find('ul').first().slideDown(300);
      } else {
        checkElement.removeClass('init-arrow-up');
        checkElement.removeClass('arrow-up');
        checkElement.removeClass('active');
        checkElement.addClass('arrow-down');
        checkElement.find('ul').slideUp(300);

      }
    });

  };
  $(function () {
    $('select').selectpicker();
    // $('#picker1').selectpicker();
    // $('#picker2').selectpicker();
    // $('#picker3').selectpicker();
    // $('#picker4').selectpicker();
});
$(function () {
  $('#datetimepicker1,#datetimepicker2,#datetimepicker11').datetimepicker({
    useCurrent:true,
    format:"MM/DD/YY"
  });
});


}

